@import url('//fonts.googleapis.com/css?family=Open+Sans:300');
@import url('//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400');
@import url('//fonts.googleapis.com/css?family=Chivo:400,900');
@import "include-media.scss";

body{
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight:300;
}

section{
  overflow:hidden;
}

h1, h2, h3, h4{
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-weight:300;
}


strong{
  font-weight: bold;
  color:#9a3234;
}

[v-cloak]{
  display: none;
}

.ghost-link{
  background:none;
  border:1px solid #CCC;
  color:#ffffff;
  border-radius:20px;
  font-size:1em;
  padding:12px 20px;
  margin-top:20px;
  display: inline-block;
  -webkit-transition:background 0.5s ease-in-out;
  -moz-transition:background 0.5s ease-in-out;
  -o-transition: background 0.5s ease-in-out;
  transition: background 0.5s ease-in-out;
  &:active, &:visited, &:focus{
    background:none;
    color:#ffffff;
  }
  &:hover{
    background:rgba(255,255,255,0.1);
    border:1px solid #FFFFFF;
    color:#ffffff;
    text-decoration: none;

  }
}

.modal{
  color:#cccccc;
}
.modal-header, .modal-footer{
  border-top:none;
  border-bottom: none;
}

.modal-header{
  text-align: center;
  h3{
    margin-top: 16px;
    background: #9a3234;
    padding:10px;
    color:#FFFFFF;
    border-radius:8px;
    @include media("<=smallscreen"){
      font-size: 1.3em;
    }
  }
}

.modal-content{
  background-color: #222222;
  border:1px solid #444;
  box-shadow: 10px 10px 16px 0px rgba(0,0,0,0.85);
  img{
    border:1px solid #444;
  }
}
.btn.btn-ab{
  background: #9a3234;
  color:#ffffff;
  font-size:1.1em;
  &:hover{
    background: #c62f32;
  }
  &:active, &:focus{
    outline: none;
    border:none;
  }

}

.img-centered{
  margin:0 auto;
}

.gplaceholder{
  margin:20px 0px;
  vertical-align:middle;
  min-height:220px;
}

.list{
  list-style-position: inside;
  margin-left: 12px;
}

.white{
  color:#FFFFFF;
}

.gray{
  color:#CCCCCC;
}

.clear{
  clear: both;
}

.button {
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  font-family: inherit;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 1.25em;
  position: relative;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  padding-top: 0.75em;
  padding-right: 1.5em;
  padding-bottom: 0.8125em;
  padding-left: 1.5em;
  font-size: 1em;
  background-color: #9a3234;
  border-color: #571516;
  color: white;
}

.button:hover, .button:focus {
    background-color: #732021;
}

.button:hover, .button:focus {
    color: white;
}

.vcenter {
    display: flex;
    align-items: center;
}

nav{
  position:fixed;
  background:#111111;
  min-height:60px;
  display:flex;
  align-items: center;
  justify-content: right;
  flex-direction: row-reverse;
  width:100%;
  z-index:1000;
  padding:0px 40px;
  top:-80px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  @include media("<=tablet"){
    justify-content: center;
    padding:0px 0px;
  }
  &.viz{
    top:0px;
  }
  ul{
    display: block;
    margin:0px;
    padding:0px;

    @include media("<=tablet"){
      margin:10px;
    }
    li{
      list-style: none;
      color:#ffffff;
      display: inline-block;
      margin-right:10px;
      @include media("<=tablet"){
        margin-left: 10px;
        margin-right: 10px;
        flex: 1 1 0;
      }
      &:last-child{
        margin-right:0px;
      }
      &:hover{
        cursor: pointer;
      }
      a{
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        text-decoration: none;
        color:#ffffff;
        background:#222222;
        padding:8px 16px;
        border-radius:6px;
        @include media("<=tablet"){
          background: none;
          text-align: center;
          display: block;
          width:50px;
          padding:0px;
        }

        &:visited, &:active, &:focus{
          color:#ffffff;
          text-decoration: none;
        }
        &:hover{
          text-decoration: none;
          color:#ffffff;
          background:#9a3234;
          @include media("<=tablet"){
            background: none;
          }
          i.fa{
            color:#efefef;
            @include media("<=tablet"){
              color:#9a3234;
            }
          }
        }
        i.fa{
          margin-right:8px;
          @include media("<=tablet"){
            display: block;
            font-size: 2em;
            margin-bottom: 4px;
            margin-right:0px;
          }
          color:#666666;
        }
      }
    }
  }
}


.contact-form{
  display:block;
  /*align-items: center;*/
  min-height:100vh;
  background:#222222;
  border-bottom:20px solid #9a3234;
  color: #cccccc;
  padding-top:40px;
  @include media("<=tablet"){
      display: block;
  }
  .row.top{
    display:flex;
    align-items: center;
    margin:0px 60px 40px 60px;
    @include media("<=tablet"){
      display: block;
      margin:0px 20px 20px 20px;
    }
    @include media("<=smallscreen"){
      font-size: 0.9em;
      margin:0px 0px 20px 0px;
    }
  }
  h2{
    color: #ffe68b;
    margin-top:0px;
    @include media("<=smallscreen"){
      font-size: 1.7em;
    }
  }
  img{
    max-height: 200px;
  }
  p{
    font-size: 1.4em;
    padding-top:10px;
    @include media("<=smallscreen"){
      font-size: 1.1em;
      line-height: 1.7em;
    }
  }
  .group{
    background: #333333;
    margin:40px 60px;
    padding: 40px 40px;
    border-radius: 10px;
    overflow: hidden;
    @include media("<=smallscreen"){
      margin:0px 0px 30px 0px;
      padding: 10px 10px;
    }
    .success-msg{
      background: #4f994c;
      border-radius: 10px;
      overflow: hidden;
      display: block;
      margin-bottom: 40px;
      h1{
        color:#ffffff;
        margin:30px 0px 10px 190px;
        @include media("<=tablet"){
          margin:30px 0px 10px 30px;
          font-size:1.6em;
          text-align: left;
        }
        @include media("<=smallscreen"){
          margin:30px 0px 10px 20px;
        }

      }
      p{
        color:#efefef;
        padding:0px 40px 30px 40px;
        margin:0px 0px 0px 150px;
        @include media("<=tablet"){
          margin:0px 0px 0px 0px;
          font-size:1.2em;
        }
        @include media("<=smallscreen"){
          font-size:1em;
          padding:0px 20px 30px 20px;
        }
      }
      &:before {
        content: "\f1d8";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        position: absolute;
        font-size: 100px;
        color: #ffffff;
        margin-top: 10px;
        margin-left:40px;
        z-index: 1;
        opacity: 0.5;
        @include media("<=tablet"){
          content:'';
          margin-left:0px;
          margin-right: 0px;
        }
      }
    }
    .success-enter-active, .success-leave-active{
      transition: all 0.5s ease-in-out;
      opacity: 1;

    }

    .success-enter, .success-leave-active /* .fade-leave-active in <2.1.8 */ {
      opacity: 0 !important;
      transform: translateY(-300px) !important;

    }

    h1{
      color:#666666;
      margin-bottom:20px;
      margin-top: 0px;
      @include media("<=tablet"){
        font-size:1.6em;
      }
      @include media("<=smallscreen"){
        font-size: 1.4em;
        text-align:center;
        margin-top: 12px;
      }
    }
    .help-block{
      font-size: 1.5em;
      font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
      font-weight:300;
      @include media("<=smallscreen"){
        font-size:1em;
      }
      div{
        display: inline-block;
        i{
          font-size: 0.6em;
          padding:0px 10px;
        }
      }
      .fade-enter-active, .fade-leave-active{
        transition: all 0.5s ease-in-out;
        opacity: 1;
      }

      .fade-enter, .fade-leave-active /* .fade-leave-active in <2.1.8 */ {
        opacity: 0 !important;
        transform: translateX(100px) !important;
      }
    }

    .form-control{
      color: #ffffff;
      background: #444444;
      border-left:none;
      border-right:none;
      border-top:none;
      border-radius: 0;
      border-bottom:1px solid #777777;
      font-size:1.2em;
      padding:20px 20px;
      display: block;
      box-shadow: none;
      @include media("<=smallscreen"){
        font-size:1em;
      }
    }

    .has-error .form-control{
      border-bottom:1px solid #af0101;

    }
    .has-success .form-control{
      border-bottom:1px solid #05821a;
    }
  }
}

header.jumbotron, section.jumbotron{
  background:#222222;
  background-image: url('../img/ab_logo_header_bg_001.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color:#FFFFFF;
  padding:50px 0px;
  position: relative;
  display:flex;
  align-items: center;
  height:100vh;
  margin:0px;
  width:100%;
  border-bottom:20px solid #9a3234;

  @include media("<=phone") {
    background-attachment: scroll;
    padding:0px 15px;
  }
  @include media(">=desktop"){
    padding:0px 0px;
  }
  @include media(">=widescreen"){
    padding:0px 200px;
  }

  .container{
    @include media("<desktop"){
      width:100%;
    }
  }

  .row{
    text-align: center;
    margin:0 auto;
    @include media(">tablet") {
      display: flex;
      align-items: center;
    }

    img.img-responsive{
      text-align: right;
      @include media("<=tablet") {
        margin: 0 auto;
      }
    }

  }


  h1{
    font-size: 3.6em;
    line-height: 1em;
    @include media("<=smallscreen"){
      font-size: 1.4em;
    }
    @include media("<=tablet") {
      font-size: 2em;
    }
  }
  .logo{
    text-align: center;
  }

  h2{
    color:#e8e3cb;
    font-size:1.8em;
    padding:0px 30px;

    @include media("<=tablet") {
      font-size: 1.3em;
      margin-top:10px;
    }

    @include media("<=smallscreen"){
      font-size: 1.2em;
    }
  }

  p{
    font-size: 1.1em;
    padding-top:10px;
  }
}

section.jumbotron{
  background-image: url('../img/folio_header_bg_001.jpg');
  height:auto;
  min-height:60vh;
  width:100%;
  h1{
    @include media("<=smallscreen"){
      text-align: left;
    }
  }
  h2{
    line-height: 1.5em;
    @include media("<=smallscreen"){
      text-align: left;
      padding:0px;
    }
  }
}

footer{
  background:#394240;
  color:#FFFFFF;
  width:100% !important;
  max-width:100%;
  padding:15px 0px;
  text-align:center;
}

@-webkit-keyframes grdAnim {
    0%{background-position:15% 0%}
    50%{background-position:86% 100%}
    100%{background-position:15% 0%}
}
@-moz-keyframes grdAnim {
    0%{background-position:15% 0%}
    50%{background-position:86% 100%}
    100%{background-position:15% 0%}
}
@keyframes grdAnim {
    0%{background-position:15% 0%}
    50%{background-position:86% 100%}
    100%{background-position:15% 0%}
}

@-o-keyframes grdAnim {
    0%{background-position:15% 0%}
    50%{background-position:86% 100%}
    100%{background-position:15% 0%}
}


.section-gry{

  background:#394240;
  background-image: url('../img/office_bg_003.jpg');
  background-position: center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  /*
  background: linear-gradient(327deg, #000000, #666666);//#9a3234, #ffd013);
  background-size: 400% 400%;

  -webkit-animation: grdAnim 30s ease infinite;
  -moz-animation: grdAnim 30s ease infinite;
  -o-animation: grdAnim 30s ease infinite;
  animation: grdAnim 30s ease infinite;
  */
  text-align: center;
  color:#FFFFFF;
  width:100% !important;
  max-width:100%;
  padding-top:100px;
  padding-bottom:100px;
  @include media("<=smallscreen"){
    text-align: left;
    padding-top:10px;
    padding-bottom:10px;
  }
  .content{
    background-color: rgba(30,30,30,0.9);
    padding:20px 40px;
    font-size:1.6em;
    z-index:999;
    @include media("<=tablet") {
      font-size:1.3em;
    }
    @include media("<=smallscreen"){
      font-size: 1em;
      padding:20px 10px;
    }
  }

  .icons-service{
    margin:60px auto;
    overflow: hidden;
    @include media("<=desktop"){
      text-align: center;
      margin:60px auto 0px auto;
      padding:0px 10px;
    }
    @include media("<=smallscreen"){
      text-align: center;
      margin:0px auto;
      padding:0px 10px;
    }

    div{
      padding:30px;
      font-size:0.7em;
      height:200px;
      top:800px;
      color:#9a9a9a;
      transition: 0.8s ease-in-out;
      @include media("<=desktop") {
        padding:6px;
        font-size:0.7em;

      }
      @include media("<=tablet") {
        padding:8px;
        font-size:0.7em;
        display: inline-block;
        width:30%;
      }
      @include media("<=smallscreen"){
        display: inline-block;
        height:auto;
        width:49%;
        margin:20px auto;
        font-size:1.2em;
      }

    }

    div.viz{
      top:0px;
    }

    div img{
      opacity: 0.5;
      transition: 0.1s ease-in-out;
      @include media("<=desktop"){
        margin:0 auto;
        width:90%;
      }
      @include media("<=smallscreen"){

        margin:0 auto;

      }
    }

    div img:hover{
      opacity: 1;
    }
  }

  ul{
    height:660px;
    width:540px;
    position:relative;
    padding:0px;
    margin:100px auto 40px auto;
    display: block;
    @include media("<=smallscreen"){
      display: none;
    }
    li{
      border-radius: 50%;
      width: 150px;
      height: 150px;
      line-height: 130px;
      background:#efefef;
      border:5px solid #9a3234;
      color:#9a3234;
      text-align: center;
      list-style: none;
      font-weight:bold;
      position:absolute;
      top:225px;
      left:195px;
      z-index: 1;
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      opacity: 0;
      span{
        display: inline-block;
        vertical-align: middle;

        span{
          font-size:0.7em;
        }
      }
    }

    li:nth-child(1){
      opacity: 1;
      position:absolute;
      top:195px;
      left:170px;
      width: 200px;
      height: 200px;
      line-height: 190px;
      background: #9a3234;
      color:#ffffff;
      border: none;
      z-index: 2;

      span{
        display: inline-block;
        vertical-align: middle;

        span{
          font-size:1em;
          line-height: 1.3em;
        }
      }

    }
    li:nth-child(2).viz{
      position:absolute;
      top:120px;
      left:0px;
      opacity: 1;
    }

    li:nth-child(3).viz{
      position:absolute;
      top:0px;
      left:190px;
      opacity: 1;
    }

    li:nth-child(4).viz{
      position:absolute;
      top:120px;
      left:390px;
      opacity: 1;
    }

    li:nth-child(5).viz{
      position:absolute;
      top:320px;
      left:0px;
      opacity: 1;
    }

    li:nth-child(6).viz{
      position:absolute;
      top:320px;
      left:390px;
      opacity: 1;
    }

    li:nth-child(7).viz{
      position:absolute;
      top:440px;
      left:190px;
      opacity: 1;
    }

  }

  h3{
    color:#d8d8d8;
    font-size: 1.3em;
    @include media("<=desktop"){
      font-size: 1.2em;
    }
    @include media("<=smallscreen"){
      font-size:1.2em;
      line-height: 1.5em;
      margin-top:8px;
    }
  }

  h2{
    /* color:#eeeeee;*/
    font-size:2em;
    color:#ffe68b;
    @include media("<=desktop"){
      font-size:1.6em;
    }
    @include media("<=smallscreen"){
      font-size:1.8em;
    }
  }

  .underline{
    border-bottom:#ffbb00 1px solid;
    color:#FFFFFF;
  }

  hr{
    border-top: 1px solid #666;
    border-bottom:none;
    border-left:none;
    border-right:none;
    margin:60px 100px;
  }

  p{
    margin-top:40px;
    color:#CCC;
    padding:20px 40px;
    @include media("<=desktop"){
      padding:20px 10px;
    }
    @include media("<=smallscreen"){
      margin-top:0px;
      padding:20px 0px;
      font-size: 1.1em;
      text-align: left;
    }
  }

}

.section-red, .section-red-top{
  background:#9a3234;

  width:100% !important;
  max-width:100%;
  overflow: hidden;
  font-size:1.8em;
  padding:30px 0px 70px 0px;
  color: #ffcfcf;
  //transform: rotateX(180deg);

  h1{
    margin-bottom:30px;
    color: #ffffff;
  }

}
.section-red-top{
  .container{
     .row{
      @include media("<=tablet") {
        padding:0px 20px;
      }
      h1{
        @include media("<=tablet") {
          font-size:1.1em;
        }
        @include media("<=smallscreen"){
          font-size:0.8em;
        }
      }
      p{
        @include media("<=tablet") {
          font-size:0.9em;
        }
        @include media("<=smallscreen"){
          font-size:0.7em;
        }
      }
    }
  }
}
.section-red{
  padding:60px 0px 90px 0px;
  font-size: 1.4em;
  line-height: 1.7em;
  @include media("<=tablet"){
    font-size:1.2em;
  }
  @include media("<=smallscreen"){
    padding:30px 0px 60px 0px;
  }
  h1{
    font-size:2.5em;
    @include media("<=smallscreen"){
      font-size: 2em;
      margin-bottom: 0px;
    }
  }

  p{
    @include media("<=smallscreen"){
      font-size:0.9em;
      margin-bottom: 20px;
    }
  }
  div{
    overflow: hidden;
  }

  .approach1, .approach2, .approach3, .approach4, .approach5{
    @include media(">=desktop"){
      -webkit-transition:left 1s ease-in-out;
      -moz-transition:left 1s ease-in-out;
      -o-transition: left 1s ease-in-out;
      transition: left 1s ease-in-out;
    }
    img{
      display: block;
      @include media(">=desktop"){
        -webkit-transition: opacity 1s ease-in-out;
        -moz-transition: opacity 1s ease-in-out;
        -o-transition: opacity 1s ease-in-out;
        transition: opacity 1s ease-in-out;
      }

    }
  }

  .approach1{
    left:-3000px;
  }

  .approach2{
    img{
      display: block;
      margin:30px auto 60px auto;
      opacity: 0;
      @include media("<=tablet"){
        margin:50px auto;
        width:60%;
      }
      @include media("<=smallscreen"){
        margin:20px auto 20px auto;
        width:90%;
      }
    }
  }

  .approach3{
    left:-3000px;
  }

  .approach4{
    left:-3000px;
  }
  .approach5{
    left:3000px;
    margin-top: 30px;
  }

  .approach1.viz,.approach2.viz,.approach3.viz,.approach4.viz,.approach5.viz{
    left:0px;
    img{
      opacity: 1;
    }
  }

  img{


  }

  .container .row{
    @include media("<=tablet"){
        padding:0px 20px;
    }
  }
}

.section-bottom{
  font-size: 1.4em;
  line-height: 1.7em;
  background: #FFFFFF;
  padding:90px 0px 60px 0px;
  @include media("<=tablet"){
    font-size:1.2em;
  }
  .vendorlogos{
      .row div{

          padding:10px;

      }
      display: block;
      margin-bottom: 30px;

  }

  h1{
    margin:0px 0px 20px 0px;
    @include media("<=smallscreen"){
      font-size:1.4em;
       margin:0px 10px 20px 10px;
    }
  }

  p{
    margin-bottom: 60px;
    text-align: center;
    @include media("<=tablet"){
      padding:0px 30px;
    }
  }

}

.latest-work,.folio-gry,.contact-top{
  background:#222222;
  color:#FFFFFF;
  font-size:1.4em;
  line-height: 1.7em;
  padding:60px 0px;
  @include media("<=desktop"){
    font-size:1.3em;
  }
  @include media("<=tablet"){
    font-size:1.2em;
  }
  @include media("<=smallscreen"){
    font-size: 1.1em;
    padding:30px 15px;
  }
  .folio-link{
    background:#333333;
    border:1px solid #444444;
    color:#666666;
    border-radius:20px;
    font-size:1em;
    padding:12px 20px;
    margin-top:20px;
    display: inline-block;

    &:active, &:visited{
      background:none;
      border:1px solid #444444;
      color:#666666;
    }
    &:hover{
      background:#9a3234;
      border:1px solid #9a3234;
      color:#ffffff;
      text-decoration: none;

    }
  }


  .latest-left-01,.latest-left-02{
    left:0px;
    @include media(">=desktop"){
      left:-3000px;
      -webkit-transition:left 1s ease-in-out;
      -moz-transition:left 1s ease-in-out;
      -o-transition: left 1s ease-in-out;
      transition: left 1s ease-in-out;
    }
    &.viz{
      left:0px;
    }
  }

  .latest-right-01,.latest-right-02{
    left:0px;
    @include media(">=desktop"){
      left:3000px;
      -webkit-transition:left 1s ease-in-out;
      -moz-transition:left 1s ease-in-out;
      -o-transition: left 1s ease-in-out;
      transition: left 1s ease-in-out;
    }
    &.viz{
      left:0px
    }
  }
  h1{
    @include media("<=smallscreen"){
      font-size:1.5em;
      margin-bottom: 20px;
    }
  }
  h3{
    color:#d8d8d8;
    font-size: 1.3em;
  }

  h2{
    /* color:#eeeeee;*/
    font-size:2em;
    color:#ffe68b;
  }

  .underline{
    border-bottom:#ffbb00 1px solid;
    color:#FFFFFF;
  }

  p{
    color:#CCC;
    padding:20px;
    @include media("<=desktop"){
      padding:20px 0px;
    }
    @include media("<=smallscreen"){
      padding: 0px 0px 20px 0px;
    }
  }
  .examples{
    margin:60px 0px;
    @include media("<=smallscreen"){
      margin:20px 0px;
    }
  }
  dl{
    dt{
      margin-bottom: 10px;
      padding:0px 0px 10px 10px;
      display: block;
      border-bottom: 1px solid #555;
    }
    dd:before{
      font-family: FontAwesome;
      content:'\f1b2';
      font-size:0.7em;
      color:#666;
      padding:0px 8px;
    }
    dd{
      margin:0px 0px 10px 15px;
      font-size: 0.85em;
    }
  }
}

.contact-top{
  font-size:1.2em;
  padding:60px 0px 0px 0px;
  .container{
    width:100%;
    padding:0% 5%;
    @include media(">desktop"){
      width:1000px;
    }
    .row{
      display:flex;
      align-items: center;
      @include media("<=tablet"){
        display: block;
      }
      h2{
        @include media("<=smallscreen"){
          font-size:1.5em;
        }
      }
    }
  }

}

.folio-gry{
  padding:40px 0px 100px 0px;
  @include media("<=smallscreen"){
    padding:20px 0px;
  }
  .container .row{
    padding: 60px 0px;
    display:flex;
    align-items: center;
    @include media("<=tablet"){
      display: block;
      padding: 40px 0px;
    }
    @include media("<=smallscreen"){
      padding:20px 0px;
    }
    h2{
      padding:0px 20px;
      @include media("<=smallscreen"){
        font-size:1.5em;
      }
      @include media("<=tablet"){
        padding:0px 0px;
      }
    }

    p{
      line-height: 2.1em;
    }
    img{
      @include media("<=tablet"){
        margin-bottom: 60px;
        margin-top:60px;
      }
      @include media("<=smallscreen"){
        margin-bottom: 20px;
        margin-top:20px;
      }
    }
  }
  .container .row:nth-child(1){
    padding:60px 0px 0px 0px;
  }
}
.section-folio-top, .section-folio-mid{
  border-bottom: 6px solid #9a3234;
  padding-top:40px;
  padding-bottom:40px;
  font-size:1.6em;
  background:#222222;
  @include media("<=smallscreen"){
    font-size: 1.2em;
  }
  h3{
    color:#d8d8d8;
    font-size: 1.3em;
  }

  h2{
    /* color:#eeeeee;*/
    font-size:2em;
    color:#ffe68b;
  }

  .underline{
    border-bottom:#ffbb00 1px solid;
    color:#FFFFFF;
  }

  hr{
    border-top: 1px solid #666;
    border-bottom:none;
    border-left:none;
    border-right:none;
    margin:60px 100px;
  }

  p{
    margin-top:40px;
    color:#CCC;
    padding:20px 40px;
  }
}
.section-folio-mid .row{
  display:flex;
  align-items: center;
  @include media("<=tablet"){
    display: block;
  }
  img{
    @include media("<=tablet"){
      width:60%;
      margin: 0 auto;
    }
  }
  p{
    margin:0px;
    @include media("<=smallscreen"){
      padding:0px 10px;
      font-size: 0.9em;
    }
  }
}
.section-folio{
  border-bottom: 6px solid #9a3234;
  border-top:20px solid #222222;
  background:#222222;
  .folio-row{
    display:block;
    padding:0;
    margin:0;
    div{
      padding:0;
      margin:0;
      position: relative !important;
      overflow: hidden;
      display: block;
      &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
      }
    }
  }
}

a.thumb-link{
  overflow: hidden;
  border:none;
  text-decoration: none;
  outline:none;
  &:active, &:visited, &:hover{
    text-decoration: none;
    border:none;
    outline: none;
  }
  img{
    z-index: 1;
    transition:  1s;
    display: block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform-origin: center center;
    background:#000000;
    background-image: url('../img/folio2/folio_thumb_placeholder.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &.show-details{
      -webkit-transform: rotateY(180deg);
      -webkit-transform-style: preserve-3d;
      transform: rotateY(180deg);
    }
  }


  .cover{
    position:absolute;
    top:0px;
    left:0px;
    z-index: 2;
    color:#FFFFFF;
    text-align: center;
    width:100%;
    height:100%;
    display: block;
    background:none;
    transform: rotateY(0deg);
    /*
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition:  1s ease-in-out;
    transition:  1s ease-in-out;
    */
    transition:  1s;
    -webkit-transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    transform: rotateY(180deg);
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    @include media("<=smallscreen"){
      font-size: 0.8em;
    }
    span{
      visibility: hidden;
      display: block;
    }
    span.caption-view{
      border:1px solid #444444;
      border-radius:6px;
      background:rgba(255,255,255,0.1);
      color:#ffffff;
      padding:8px 20px;
      margin:10px 10px;
      display: inline-block;
    }
    span.caption-view:hover{
      background:#9a3234;
    }
    i{
      margin-top:20%;
      margin-bottom:20px;
      color:#444444;
      font-size:3.5em;
      display: block;
    }
    .caption{
      display:block;
      position: relative;
      text-align: center;
      width: 100%;
      font-size:1.2em;
      color:#FFFFFF;
    }
    &.show-details{
      width:100%;
      height:100%;
      transition:  1s;
      display: block;
      -webkit-transform:rotateY(0deg);
      -webkit-transform-style: preserve-3d;
      transform: rotateY(0deg);
      transform-style: preserve-3d;
      transform-origin: center center;
      background:rgba(0,0,0,0.2);
      border:1px solid #444444;
      span{
        visibility: visible;
      }
    }
  }
}
.folio-block{
  padding:40px 100px;
  font-size: 14px;
  line-height: 1.42857143;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include media("<=desktop"){
    padding: 40px 20px;
    font-size: 0.6em;
  }

  @include media("<=tablet"){
    justify-content: center;
    padding: 40px 40px;
    font-size: 0.8em;
  }

  @include media("<=smallscreen"){
    padding: 40px 20px;
    font-size: 1em;
  }

  &.first{
    padding:0px 100px 40px 100px;

    @include media("<=desktop"){
      padding: 0px 20px 40px 20px;
      font-size: 0.6em;
    }

    @include media("<=tablet"){
      padding:0px 40px 40px 40px;
      font-size: 0.8em;
    }

    @include media("<=smallscreen"){
      padding:0px 20px 40px 20px;
      font-size: 1em;
    }
  }

  .col-sm-3, .col-xs-6{
    padding-left: 0px;
    padding-right: 0px;


    @include media("<=tablet"){
      max-width: 250px;
      max-height: 250px;
      flex-shrink: 1;
    }

    @include media("<=smallscreen"){
      max-width: 215px;
      max-height: 215px;
    }

  }

  img{
    @include media("<=tablet"){
      margin:0px 0px !important;
    }
    @include media("<=smallscreen"){
      margin:0px 0px !important;
    }
  }
}

.row{
  &.tech{
    margin-left:170px;
    @include media("<=widescreen"){
      margin-left:80px;
    }
    @include media("<=desktop"){
      margin-left:50px;
    }
    @include media("<=tablet"){
      margin-left:0px;
    }
  }
}
